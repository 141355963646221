import React from 'react'
import AdobeDark from '../assets/images/AdobeDark.png'
import '../styles/FinalScreen.scss'

export default function FinalScreen(props) {

    const { visible, goBack, activeLink } = props;

    return (
        <div className={`final-screen-container ${ visible ? '': 'hidden' }`}>
            <div className="final-screen-inner">
                <div></div>
                <div className="final-screen-text">
                    <h2>Learn More</h2>
                    <p>Learn more about what Adobe Experience Manager can do for your business.</p>
                    <a href="https://www.adobe.com/marketing/experience-manager.html"><p>Explore the customer showcase</p></a>
                    <button onClick={ () => goBack(activeLink)} className="btn btn-large faas-grey">Go Back</button> 
                </div>
                <div className="final-screen-image">
                    <img src={ AdobeDark } alt="Adobe Experience Cloud logo with dark background"/>
                    <p><strong>Adobe Experience Manager</strong></p>
                </div>
            </div>
        </div>
    )
}

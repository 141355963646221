import EventEmitter from './EventEmitter';
import InertiaHandler from './InertiaHandler';
import ActionPoint from './ActionPoint';

const checkIntWithinRange = (intToCheck, minValue, maxValue) => (intToCheck > minValue) && (intToCheck < maxValue);

export default class Timeline extends EventEmitter {
    constructor(animationRoot, actionPoints) {
        super();
        window.acc_debug.push('Timeline created');

        this.currentFrame = 0;
        this.percentComplete = 0;
        this.totalFrames = window.exportRoot.totalFrames;
        this.atStart = true;
        this.atEnd = false;
        this.animationPause = false;
        this.scrollDirection = 'down';
        this.currentNavPoint = 0;

        this.navigationPoints = [0, 3182, 4276, 4970];

        this.actionPoints = actionPoints.map(aPoint => new ActionPoint(this, aPoint));
    
        this.inertiaHandler = new InertiaHandler();
        
        // these will only be needed if keeping track of different sections of the page
        this.currentSectionIndex = 0;
        this.onSubAnimation = false;

        // Changeable configurations
        this.scrollSpeed = 12;
        this.firefoxScrollSpeed = 2;
        this.animationStepTolerance = 15;
        this.scrollNavFadeBreakpoint = 10;

        this.on('navigation_clicked', index => {
            if (this.currentNavPoint !== index) {
                this.handleNavPointChange(index);
            }
        });

        this.on('set_animation_pause', value => {
            this.animationPause = value;
            window.acc_debug.push(`Timeline.js: set_animation_pause listener: ${value}`);
        });

        this.on('scroll_bar_click', scrollPercentage => {
            const endFrame = (this.totalFrames / 100) * scrollPercentage;
            window.acc_debug.push(`Timeline.js: renderLoop: true`);

            this.handleScrollBarClick(endFrame);
        });

        this.bindInertiaHandler = e => this.inertiaHandler.beginInertia(e);

        if (navigator.userAgent.indexOf("Firefox") > -1) {
            window.addEventListener('DOMMouseScroll', this.bindInertiaHandler);
        } else {
            window.addEventListener('mousewheel', this.bindInertiaHandler);
        }

        this.inertiaHandler.on('inertia_value', val => {
            this.handleScroll(val);
        });

        this.inertiaHandler.on('new_scroll_direction', val => {
            window.acc_debug.push(`Timeline.js: new_scroll_direction listener: ${val}`)
            this.setScrollDirection(val)
        });

    }

    unBindScrollEvent() {
        if (navigator.userAgent.indexOf("Firefox") > -1) {
            window.removeEventListener('DOMMouseScroll', this.bindInertiaHandler);
        } else {
            window.removeEventListener('mousewheel', this.bindInertiaHandler);
        }
    }
 
    handleScrollBarClick(endFrame) {
        window.exportRoot.gotoAndStop(endFrame);
        this.currentFrame = endFrame;
    }

    setPercentComplete() {
        this.percentComplete = Math.ceil((this.currentFrame / this.totalFrames) * 100);
        this.emit('percent_set', this.percentComplete);
    }

    isLastSection() {
        if (this.parentTimeline.currentSectionIndex === this.parentTimeline.subAnimations.length) return true;
        else return false;
    }

    setScrollDirection(value) {
        this.scrollDirection = value;
    }


    setAnimationBoundary(scrollStep) {
        if (this.scrollDirection === 'up') {
            this.atStart = this.currentFrame - scrollStep <= 0;
            if (this.atStart) this.currentFrame = 0;
            this.atEnd = false;

            if (this.atStart) this.emit('animation_start');
        }
        if (this.scrollDirection === 'down') {
            this.atEnd = this.currentFrame + scrollStep >= this.totalFrames;
            if (this.atEnd) this.currentFrame = this.totalFrames;
            this.atStart = false;
            if (this.atEnd) this.emit('animation_end');
        }
    }

    unNormalizeValue(value) {
        return this.scrollDirection === 'up' ? value * -1 : value;
    }

    handleNavPointChange(index) {
        this.currentNavPoint = index;
        const frameCount = this.navigationPoints[index]
        this.handleScrollBarClick(frameCount); 
        const calcPercent = frameCount / this.totalFrames * 100;
        this.emit('percent_set', calcPercent);
    }

    handleActionPoints() {
        const flooredCurrentFrame = Math.floor(this.currentFrame);
        const currentActionPoints = this.actionPoints.filter(aPoint => {
            const keepKey = checkIntWithinRange(flooredCurrentFrame, (aPoint.point - this.animationStepTolerance), (aPoint.point + this.animationStepTolerance));
            return keepKey;
        });

        if (!!currentActionPoints.length) currentActionPoints.forEach(aPoint => aPoint.toggleActive(true));
        else this.actionPoints.forEach(aPoint => aPoint.toggleActive(false));
    }

    checkNavPoint() {
        const newNavPointIndex = this.navigationPoints.reduce((acc, curr, i) => {
            if (this.currentFrame >= curr) return i;
            else return acc;
        }, 0);

        if (newNavPointIndex !== this.currentNavPoint) {
            this.currentNavPoint = newNavPointIndex;
            this.emit('nav_point_changed', this.currentNavPoint);
        }

        
    }
    
    handleScroll(value) {
        if (this.animationPause) return;

        this.handleActionPoints();

        const scrollDeltaValue = navigator.userAgent.indexOf("Firefox") > -1 ? value * this.firefoxScrollSpeed : value / this.scrollSpeed;
        
        const scrollAmount = this.unNormalizeValue(scrollDeltaValue);
        
        this.setAnimationBoundary(scrollAmount);
        this.currentFrame += scrollAmount;

        this.checkNavPoint();        

        this.setPercentComplete();
        window.exportRoot.gotoAndStop(this.currentFrame);

        this.emit('animation_scroll', this.currentFrame >= this.scrollNavFadeBreakpoint)
    }
}
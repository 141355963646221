
import React from 'react';
import '../styles/DebugLog.scss';
window.acc_debug = [];

function DebugLog(props) {    

   return (
       <div id="debug-log" className={ props.showDebug ? 'show': '' }>
           <h1>Debug Log</h1>
           <p>If you are experiencing any weird behaviour, namely related to scrolling functionality, please copy out the whole log and email it to Josh along with the steps you took to arrive at that point. </p>

           <ul>
            { window.acc_debug.map((log, i) => (<li key={ i }>{ log }</li>)) }
           </ul>
       </div>
   )
}

export default DebugLog;
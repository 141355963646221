import React, { useEffect, useRef } from 'react';
import '../styles/AnchorPoint.scss';

const AnchorPoint = props => {
    
    const { onFire } = props;

    const domRef = useRef();
    useEffect(() => {
      const localRef = domRef.current;
        const options = { threshold: 0.7 };
        const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && onFire) {
            onFire();
          }
        }, options);
        
        observer.observe(localRef);
        
        return () => observer.unobserve(localRef);
      });


    return (
        <a ref={ domRef } href={ `#${props.sectionName}` } className="anchor-point" name={props.sectionName}>{ props.sectionName }</a>
    )
}

export default AnchorPoint;
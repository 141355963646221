import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
        <Router>
            <Switch>
                <Route path="/au">
                    <App region="en-gb"/>
                </Route>
                <Route path="/uk">
                    <App region="en-uk"/>
                </Route>
                <Route path="/nz">
                    <App region="en-gb"/>
                </Route>
                <Route path="/ca">
                    <App region="en-us"/>
                </Route>
                <Route path="/">
                    <App region="en-us"/>
                </Route>
            </Switch>
        </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState } from 'react'
import useOnce from '../hooks/useOnce'
import ArrayObserver from '../scripts/ArrayObserver'
import '../styles/Faas.scss'
import AdobeDark from '../assets/images/AdobeDark.png'
import FinalScreen from './FinalScreen';

import '../styles/Toast.scss';

export default function Faas(props) {

    const { faas, setFaas, tracking, activeLink, pauseAnimation, setToastOpen, region } = props

    const [ faasFailed, setFaasFailed ] = useState(false);

    const goForward = (skipped) => {
        if (skipped) tracking('First FAAS Skipped');
        skipped ? setFaas({ ...faas, visible: false }) : setFaas({ ...faas, completed: true, visible: false })
        window.masterTimeline.emit('set_animation_pause', false);
    }

    const goBack = index => {
        window.masterTimeline.emit('navigation_clicked', index);
        pauseAnimation(false);
        setFaas({ ...faas, visible: false })
    }

    useOnce(() => {
        if (!faas.completed) {;
            const intervalId = setInterval(() => {
                if (window.digitalData && window.digitalData.form) {
                    clearInterval(intervalId);

                    const arrayObserver = new ArrayObserver(window.digitalData.faasEvent);
                    arrayObserver.observe(() => {
                        const { form } = window.digitalData;
                        if (form && form.response && form.response.submissionID) {
                            window.localStorage.setItem('faasSubmitted', true);

                            if (faas.skippable) {
                                tracking('First FAAS Submitted')
                                goForward(false)
                            } else {
                                setFaas({ ...faas, completed: true })
                                tracking('Final FAAS Submitted');
                            }

                            setToastOpen(true);

                        }
                    })
                }
            }, 100)
        }

        let regionFaasId;
        switch(region) {
            case "en-gb":
                regionFaasId = "7011O000002e1BWQAY"
                break;
            case "en-uk":
                regionFaasId ="7015Y000001zSHxQAM"
                break;
            case "en-us":
                regionFaasId = "7015Y000001zDskQAE"
                break;
            default:
                regionFaasId = "7015Y000001zDskQAE"
        }

        if (document.querySelector('#form')) {
            if (window.jQuery && document.querySelector('#form').childElementCount < 1) {;
                setFaasFailed(false);

                window.$("#form").faas({
                    //change targeted element to fit your page
                    id: 80, //formtemplate id
                    l: "en_us", //local - optional
                    // d: "/turntable", // destination url
                    as: true, //autosubmit - optional
                    ar: false,
                    pc: {
                        1: "js",
                        2: "faas_submission",
                        3: "sfdc"
                    },
                    p: {
                        js: {
                            36: regionFaasId,
                            77: "1",
                            78: "1",
                            79: "1",
                            90: "AECBU",
                            92: "2846", //form_type
                            93: "2848", //form_subtype
                            94: "3149", // product of interest
                        },
                    },
                });
            } else {
                if (document.querySelector('#form').childElementCount >= 1) console.log('form already initialised with child els')
                else if (window.jQuery === undefined) {
                    console.error('jQuery deps not found - ensure that your CDN is working and you are connected to the internet')
                    setFaasFailed(true)
                }
            }
        }
    });

    return (
        !faas.skippable && faas.completed ? <FinalScreen visible={faas.visible} goBack={goBack} activeLink={activeLink} /> :
            <div className={`faas-container ${faas.visible ? !faas.skippable ? 'final' : '' : 'hidden'}`}>

                <div className="faas-inner">
                    <div className="faas-content">
                        <h1>Find Out More</h1>
                        <p>Let's talk about what Adobe Experience Manager can do for your business. Fill in your details and an expert from our team will be in touch.</p>
                        {faas.skippable ? <button onClick={() => goForward(true)} className="btn btn-large faas-grey">Skip & Continue Demo</button> :
                            <button onClick={() => goBack(activeLink)} className="btn btn-large faas-grey">Go Back</button>}
                    </div>
                    {faasFailed ?
                        <div className="faas-screen-text">
                            <h2>Form Currently Unavailable</h2>
                            <p>Please use the link below to learn more about what Adobe Experience Manager can do for your business.</p>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.adobe.com/marketing/experience-manager.html"><p>Explore the customer showcase</p></a>
                        </div> :
                        <div id="form"></div>}
                </div>
                {
                    !faas.skippable ?
                        <div className="learn-more-container">
                            <div className="learn-more-inner">
                                <img src={AdobeDark} alt="Adobe Experience Cloud logo with dark background" />
                                <h2>Learn More</h2>
                                <p>Learn more about what Adobe Experience Manager can do for your business.</p>
                                <a href="https://www.adobe.com/au/marketing/experience-manager.html" target="_blank" rel="noopener noreferrer" >
                                    <button className="btn btn-large">Explore AEM</button>
                                </a>
                            </div>
                        </div>
                        :
                        ""
                }
            </div>
    )
}

import React, { useState, useEffect } from 'react';
import AdobeHorizontal from '../assets/images/AdobeHorizontal.png'
import AdobeVertical from '../assets/images/AdobeVertical.png'
import MobileAdobeLogo from '../assets/images/MobileAdobeLogo.png'
import '../styles/Navigation.scss';

const navigationLinkData = [ 'Content', 'Scale', 'Efficiency', 'Get in Touch' ];
const formatNavHref = navText => navText.toLowerCase().replace(/ /g, '-');


const Navigation = props => {
    
    const { activeLink, setActiveLink, fireCustomEvent, faas, setFaas, pauseAnimation } = props;

    const [ menuOpen, setMenuOpen ] = useState(false);
    const [ deviceSize, setDeviceSize ] = useState('mobile');

    const linkClick = index => {
        if (deviceSize === 'mobile') setMenuOpen(false);
        else window.masterTimeline.emit('navigation_clicked', index);
        setActiveLink(index);
        if (faas.visible) {
            pauseAnimation(false);
            setFaas({
                ...faas,
                visible: false
            });
        }
    }

    useEffect(() => {

        function handleResize() {
            setDeviceSize(window.screen.availWidth > 994 ? 'desktop' : 'mobile')
        }
        handleResize();
        window.addEventListener('resize', handleResize);
    }, [deviceSize]);

    const generateNavigationLinks = linkData => {
        return linkData.map((linkText, index) => (
            <a key={ index } href={`#${formatNavHref(linkText)}`} 
            onClick={() => {linkClick(index); fireCustomEvent(`Navigation ${linkText.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} Click`)}}>
                <li className={index === activeLink ? 'active' : ''}>{ linkText }</li>
            </a>)
        )
    }

    return (
        <nav id="navigation" className={ `
            ${ menuOpen ? 'open' : '' }
            ${ props.animationIsScrolled ? 'scrolled' : '' }` }>

            <div className="mobile-shade" onClick={ () => setMenuOpen(false) }></div>
            <div className="nav-inner-wrapper">
                <div className="left-wrapper"> 
                    <div className="logo-title-wrapper">
                        <div className="hamburger-menu-icon" onClick={ () => setMenuOpen(true) }>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <img className="nav-image-desktop" src={ AdobeHorizontal } alt="Adobe's 'A' Logo in red" />
                        <img className="nav-image-mobile" src={ MobileAdobeLogo } alt="Adobe's 'A' Logo in red" />
                        <p className="title"><span className="adobe-text">Adobe&nbsp;</span>Experience Manager</p>
                    </div>
                    <ul className="navigation-buttons">
                        <div className="top-container">
                            <img className="menu-icon" src={ AdobeVertical } alt="hamburger menu icon"/>
                            <h4 className="title">Adobe Experience Manager</h4>
                            <div className="close-btn" onClick={ () => setMenuOpen(false) }></div>
                        </div>
                        { generateNavigationLinks(navigationLinkData) }
                    </ul>
                </div>
                <div className="right-wrapper">
                    <a href="https://www.adobe.com/au/request-consultation/experience-cloud.html" target="_blank" rel="noopener noreferrer">
                        <button className="btn">Get in touch</button>
                    </a>
                </div>
            </div>
        </nav>
    )
}

export default Navigation;
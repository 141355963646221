import React from 'react';
import '../styles/MobileSection.scss';
import ScrollAnimate from './ScrollAnimate';

const MobileSection = props => {
    return (
        <div className={`mobile-section text-${props.textColor} bg-${props.bgColor}`}>
                <div className={`body-section-wrapper ${props.bottomPadding === false ? 'no-bottom-padding' : ''}`}>
                    { props.noAnimate ? 
                        props.children :
                        props.children.map((el, i) => (<ScrollAnimate key={ i }>{el}</ScrollAnimate>))
                    }
                </div>
        </div>
    )
}

export default MobileSection;
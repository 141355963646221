import React, { useState } from 'react';
import '../styles/Footer.scss';
import Region from '../assets/images/region.svg';
import RegionLightBox from './RegionLightBox';

const Footer = props => {
    const [ isDismissed, setIsDismissed ] = useState(false);
    const [ lightboxVisible, setLightboxVisible] = useState(false);

    return (
        <footer id="footer" className={ `${ isDismissed ? 'hidden' : '' }` }>
            <div className="footer-inner">
                <ul className="footer-links">
                    <li onClick={() => setLightboxVisible(true)} className="region"><img src={Region} alt="Region icon" />Change region</li>
                </ul>
                <ul className="footer-links">
                    <a href="https://www.adobe.com/privacy.html"><li>Privacy</li></a>
                    <a href="https://www.adobe.com/legal/terms.html"><li>Terms of Use</li></a>
                    <li onClick={ props.animationPauseFunc } className="end">Cookie Preferences</li>
                    <button onClick={() => setIsDismissed(true)} className="btn secondary">Dismiss</button>
                </ul> 
            </div>    
            <RegionLightBox lightboxVisible={lightboxVisible} setLightboxVisible={setLightboxVisible}/>
        </footer>
    )
}

export default Footer;
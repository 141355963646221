import React from 'react';
import '../styles/RegionLightBox.scss';
import { Link } from "react-router-dom";

const RegionLightBox = (props) => {
    const {lightboxVisible, setLightboxVisible} = props;

    return (
        <div id="region-lightbox" className={lightboxVisible ? '' : 'lightbox-hide'}>
            <div className="region-lightbox-inner">
                <button onClick={() => setLightboxVisible(false)}></button>
                <h2 className="region-lightbox-heading">Choose your region</h2>
                <p>Selecting a region changes the language and/or content on Adobe.com.</p> 
                <div className="region-lightbox-cols">   
                    <ul className="region-lightbox-links">
                        <p>Americas</p>
                        <li><Link to="/">United States</Link></li>
                        <li><Link to="/ca">Canada - English</Link></li>    
                        <p>Europe</p>
                        <li><Link to="/uk">United Kingdom</Link></li>
                    </ul>
                    <ul className="region-lightbox-links">
                        <p>Asia Pacific</p>
                        <li><Link to="/au">Australia</Link></li>
                        <li><Link to="/nz">New Zealand</Link></li>
                    </ul>
                </div>
            </div>    
        </div>
    )
}

export default RegionLightBox;
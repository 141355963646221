
import React, { useEffect, useState, useCallback } from 'react';
import useOnce from './hooks/useOnce'
import Timeline from './scripts/Timeline';
import Navigation from './components/Navigation';
import Faas from './components/Faas'
import Footer from './components/Footer';
import MobileApp from './MobileApp';
import DebugLog from './components/DebugLog';
import Toast from './components/Toast';
import './App.scss';

window.acc_debug = [];

function App(props) {    

    const { region } = props

    const [ deviceSize, setDeviceSize ] = useState(window.screen.availWidth > 768 ? 'desktop' : 'mobile');
    const [ getActiveNavLink, setActiveNavLink ] = useState(0);
    const [ showDebug, setShowDebug ] = useState(false);
    const [ toastOpen, setToastOpen ] = useState(false);

    const [ faas, setFaas ] = useState({
        skippable: true,
        completed: false,
        visible: false
    });
    
    const pauseAnimation = value => {
        window.masterTimeline && window.masterTimeline.emit('set_animation_pause', value);
        window.acc_debug.push(`pauseAnimationFunction: ${value}`);
    };

    const initCookiePopUp = () => {
        let checkPrivacy = setInterval(() => {
            if (document.querySelector('#onetrust-consent-sdk')) {
                clearInterval(checkPrivacy);

                let onetrust = document.querySelector('#onetrust-consent-sdk')

                const onetrustCloseBtns = [
                    onetrust.querySelector('#close-pc-btn-handler'), // close button
                    onetrust.querySelector('#ot-pc-logo-button #accept-recommended-btn-handler'), // enable cookies btn
                    onetrust.querySelector('#ot-pc-logo-button .disable-all-btn') // disable cookies btn
                ];
            
                onetrustCloseBtns.forEach((btn, index) => btn.addEventListener('click', () => {
                    window.acc_debug.push(`One trust close btn[${index}] clicked`);
                    window.masterTimeline && window.masterTimeline.emit('set_animation_pause', false);
                }));
            } 
        }, 15);
    }

    window.toggleDebugLog = () => {
        if (showDebug) window.acc_debug.push('Debug Log closed');
        else window.acc_debug.push('Debug Log opened');
        window.masterTimeline.emit('set_animation_pause', !showDebug);
        setShowDebug(!showDebug)
    }

    const checkCIDlist = () => {
        let present = false;
        let leadParams = [
            '7011O000002e1CKQAY',
            '7011O000002e1CFQAY',
            '7011O000002e1C0QAI',
            '7011O000002e1BvQAI',
            '7011O000002e1BqQAI',
            '7011O000002e1BlQAI',
            '7015Y000001zDt4QAE',
            '7015Y000001zDt9QAE',
        ]

        leadParams.forEach(string => {
            if (window.location.search.indexOf(string) > -1) {
                present = true
            } 
        });

        return present
    }

    const cookieClick = () => {
        window.acc_debug.push('Cookie preferences clicked');
        pauseAnimation(true);
        if (window.adobePrivacy) window.adobePrivacy.showConsentPopup();
    }

    const fireCustomEvent = (eventName) => {
        window.acc_debug.push(`Analytics Event fire: ${eventName}`);
        window.digitalData._set( 
            'digitalData.primaryEvent.eventInfo.eventName', `${eventName}` 
        ); 
        window._satellite.track('event', { digitalData: window.digitalData._snapshot() }); 
        window.digitalData._delete('digitalData.primaryEvent.eventInfo.eventName'); 
    }

    useOnce(() => {        
        initCookiePopUp()

        if (faas.completed !== true) {    
            if(!!window.localStorage.getItem('faasSubmitted') === true) {
                setFaas({ ...faas, completed: true});
                return;
            }
            else if(checkCIDlist()) { 
                console.log("SETTING LOCAL STORAGE DUE TO CID")
                window.localStorage.setItem('faasSubmitted', true);
                setFaas({ ...faas, completed: true})
                return
            }
        }
        console.log("useOnce completed")
    });

    const initActionPoints = useCallback((faas) => {

        console.log('masterTimeline on new init action points funciton', window.masterTimeline)

        let checkExportRoot = setInterval(() => {
            if (window.exportRoot) {
                clearInterval(checkExportRoot);
                if(window.adobePrivacy && window.adobePrivacy.init) window.adobePrivacy.init()

                window.masterTimeline = null;
                let masterTimeline = new Timeline(window.exportRoot, [
                    {
                        name: 'skippable form reveal',
                        point: 2130,
                        once: true,
                        cb: ctx => {
                            console.log("FAAS CHECK", faas.completed)
                            if (faas.completed === false) {
                                fireCustomEvent('Skippable FAAS Seen')
                                setFaas((f) => { return { ...faas, visible: true }});
                                window.acc_debug.push(`form reveal pause: ${true}`);
                                masterTimeline.emit('set_animation_pause', true);
                            }
                        },
                    },
                    {
                        name: 'scale scroll',
                        point: 3182,
                        once: true,
                        cb: ctx => fireCustomEvent('Scale Section Reached, Natural Scroll')
                    },
                    {
                        name: 'efficiency scroll',
                        point: 4276,
                        once: true,
                        cb: ctx => fireCustomEvent('Efficiency Section Reached, Natural Scroll')
                    },
                    {
                        name: '50% Completed',
                        point: Math.floor(window.exportRoot.totalFrames * 0.5),
                        once: true,
                        cb: ctx => fireCustomEvent('event231 50% Completed')
                    },
                    {
                        name: '75% Completed',
                        point: Math.floor(window.exportRoot.totalFrames * 0.75),
                        once: true,
                        cb: ctx => fireCustomEvent('event231 75% Completed')
                    },
                    {
                        name: 'final form reveal',
                        point: window.exportRoot.totalFrames,
                        cb: ctx => {
                            fireCustomEvent('Journey Completed')
                            window.digitalData.form && Object.keys(window.digitalData.form.response).length > 1 ? setFaas({ completed: true, skippable: false, visible: true}) : setFaas({ ...faas, skippable: false, visible: true});
                            window.acc_debug.push(`final form pause: ${true}`);
                            masterTimeline.emit('set_animation_pause', true);
                        }
                    }
                ]);

                masterTimeline.on('nav_point_changed', linkIndex => {
                    setActiveNavLink(linkIndex)
                });

                masterTimeline.on('set_animation_pause', value => {
                    window.acc_debug.push(`App.js: set_animation_pause listener : ${value}`); 
                });

                document.querySelector('#animation_container').classList.add('loaded')
                window.masterTimeline = masterTimeline;
                console.log("MASTER TIMELINE SET")
            }
        }, 15) // eslint-disable-next-line
    }, []);

    useEffect(() => {
        function handleResize() {
            setDeviceSize(window.screen.availWidth > 994 ? 'desktop' : 'mobile')
        }
        window.addEventListener('resize', handleResize)

        if (deviceSize === 'desktop' && !window.masterTimeline) {
            document.querySelector('#animation_container').classList.remove('loaded')

            let checkInit = setInterval(() => {
                if (window.AdobeAn && window.init) {
                    clearInterval(checkInit)
                    window.init(region);
                    initActionPoints(faas)
                }
            }, 10)
        }

        if (deviceSize === 'mobile') { 
            window.masterTimeline && window.masterTimeline.unBindScrollEvent();
            window.masterTimeline = null;
        };

        return function cleanup() { window.removeEventListener('resize', handleResize) };

    }, [faas, region, deviceSize, initActionPoints])


    return (
        deviceSize === "desktop" ?
            <div className="App">
                <DebugLog showDebug={showDebug} />
                <Toast setToastOpen={ setToastOpen } toastOpen={ toastOpen } />
                <Navigation faas={ faas } setFaas={ setFaas } pauseAnimation={ pauseAnimation } activeLink={ getActiveNavLink } deviceSize={ deviceSize } setActiveLink={ setActiveNavLink } fireCustomEvent={ fireCustomEvent } />
                <div id="animation_container" className={` ${faas.visible ? 'hide' : ''} `}>
                    <canvas id="canvas" width="1440" height="810"></canvas>
                    <div id="dom_overlay_container"></div>
                </div>
                <div id='_preload_div_'><img src="images/_preloader.gif" alt="loading animation squares"/></div>
                <Faas faas={ faas } setFaas={ setFaas } tracking={ fireCustomEvent } region ={ region } activeLink={ getActiveNavLink } pauseAnimation={ pauseAnimation } setToastOpen={ setToastOpen }/>
                <Footer animationPauseFunc={ cookieClick }/>
            </div>
            :
            <div className="App">
                <Navigation activeLink={ getActiveNavLink } setActiveLink={ setActiveNavLink } fireCustomEvent={ fireCustomEvent }/>
                <MobileApp region={ region } faas={ faas } setFaas={ setFaas } tracking={ fireCustomEvent } setNavLink={ setActiveNavLink }/>
            </div>
    );
}

export default App;
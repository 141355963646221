import React from 'react'
import '../styles/MobileComplete.scss'
import AdobeDark from '../assets/images/AdobeDark.png'

export default function MobileComplete() {
    return (
        <div className="learn-more-container">
            <div className="learn-more-inner">
                <img src={AdobeDark} alt="Adobe Experience Cloud logo with dark background" />
                <h2>Learn More</h2>
                <p>Learn more about what Adobe Experience Manager can do for your business.</p>
                <a href="https://www.adobe.com/au/marketing/experience-manager.html" target="_blank" rel="noopener noreferrer" >
                    <button className="btn btn-large">Explore AEM</button>
                </a>
            </div>
        </div>
    )
}

export default function ArrayObserver(a){
    var _this = this;
    this.array = a;   
    this.observers = [];

    this.observe = function (notifyCallback){
        _this.observers.push(notifyCallback);
    }    

    a.push = function(obj){
        var push = Array.prototype.push.apply(a, arguments);        
        for(var i = 0; i < _this.observers.length; i++) _this.observers[i](obj, "push");
        return push;
    }

    a.pop = function(){
        var popped = Array.prototype.pop.apply(a, arguments);        
        for(var i = 0; i < _this.observers.length; i++) _this.observers[i](popped, "pop");
        return popped;
    }

    a.reverse = function() {
        var result = Array.prototype.reverse.apply(a, arguments);
        for(var i = 0; i < _this.observers.length; i++) _this.observers[i](result, "reverse");
        return result;
    };

    a.shift = function() {
        var deleted_item = Array.prototype.shift.apply(a, arguments);
        for(var i = 0; i < _this.observers.length; i++) _this.observers[i](deleted_item, "shift");
        return deleted_item;                        
    };

    a.sort = function() {
        var result = Array.prototype.sort.apply(a, arguments);
        for(var i = 0; i < _this.observers.length; i++) _this.observers[i](result, "sort");
        return result;
    };

    a.splice = function(i, length, itemsToInsert) {
        var returnObj
        if(itemsToInsert){
            Array.prototype.slice.call(arguments, 2);
            returnObj = itemsToInsert;
        }
        else{
            returnObj = Array.prototype.splice.apply(a, arguments);
        }
        for(var j = 0; j < _this.observers.length; j++) _this.observers[j](returnObj, "splice");
        return returnObj;
    };

    a.unshift = function() {
        var new_length = Array.prototype.unshift.apply(a, arguments);
        for(var i = 0; i < _this.observers.length; i++) _this.observers[i](new_length, "unshift");
        return arguments;
    };
                
}
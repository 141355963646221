import React, { useEffect } from 'react';
import MobileSection from './components/MobileSection';
// import ScrollAnimate from './components/ScrollAnimate';

import AnchorPoint from './components/AnchorPoint';
import SectionHeading from './components/SectionHeading';
import MobileBackgroundSwirl from './assets/images/MobileBackgroundSwirl.png';
import MobileAdobe from './assets/images/MobileAdobe.png';
import MobileComplete from './components/MobileComplete'

import Mobile_2_1 from './assets/images/mobile-2-1.gif';
import Mobile_2_2 from './assets/images/mobile-2-2.gif';

import Mobile_4_1 from './assets/images/mobile-4-1.gif';
import Mobile_4_2 from './assets/images/mobile-4-2.gif';

import Mobile_5_1 from './assets/images/mobile-5-1.gif';
import Mobile_5_2 from './assets/images/mobile-5-2.svg';

import Mobile_6_1 from './assets/images/mobile-6-1.gif';
import Mobile_6_2 from './assets/images/mobile-6-2.gif';

import Mobile_7_1 from './assets/images/mobile-7-1.gif';
import Mobile_7_2 from './assets/images/mobile-7-2.gif';
// import Mobile_7_3 from './assets/images/mobile-7-3.svg';

import Mobile_8_1 from './assets/images/mobile-8-1.svg';
import Mobile_8_2 from './assets/images/mobile-8-2.gif';

import Mobile_9_1 from './assets/images/mobile-9-1.svg';

import Mobile_10_1 from './assets/images/mobile-10-1.svg';
import Mobile_10_2 from './assets/images/mobile-10-2.png';
import Mobile_10_3 from './assets/images/mobile-10-3.gif';
import Mobile_10_4 from './assets/images/mobile-10-4.gif';

import Mobile_11_1 from './assets/images/mobile-11-1.png';



import Faas from './components/Faas';
import Footer from './components/Footer';


export default function MobileApp(props) {

    const { region, setNavLink, faas, setFaas, tracking } = props;

    useEffect(() => {
        let checkPrivacy = setInterval(() => {
            if (window.adobePrivacy && window.adobePrivacy.init) {
                clearInterval(checkPrivacy);
                window.adobePrivacy.init()
            }
        }, 10)
    })

    const cookieClick = () => {
        if (window.adobePrivacy) window.adobePrivacy.showConsentPopup();
    }

    return (
        <div>            
            <div className="hero-banner-wrapper">
                <img className="hero-banner-background" src={ MobileBackgroundSwirl } alt="Adobe Experince Manager" />
                <h1 className="hero-banner-heading">Discover Adobe <br></br>Experience Manager</h1>
                <img className="hero-banner-logo" src={ MobileAdobe } alt="Adobe's 'A' Logo in White" />
                <p className="hero-banner-text">Start Tour</p>
                <div className="down-arrow-left"></div>
                <div className="down-arrow-right"></div>
            </div>
           
            <MobileSection sectionName="" textColor="black" bgColor="offwhite">
                <AnchorPoint sectionName=""/>
                <SectionHeading border="red">Create beautiful experiences on any channel</SectionHeading>
                <h3 className="red">Go from inspiration to go-live</h3>
                <p>Create, manage, and deliver the right experience for each audience at every touchpoint.</p>
                <img className= "right-padding" src={Mobile_2_1} alt="" />

                <h3 className="red">Headless without the headache</h3>
                <p>Publish the same content to web, IoT, digital kiosks, and more with GraphiQL for headless delivery.</p>
                <img className= "left-padding" src={Mobile_2_2} alt="" />
            </MobileSection>

            <MobileSection  setNavLink={() => setNavLink(0)} sectionName="content" textColor="white" bgColor="red">
                <AnchorPoint sectionName="content" onFire={() => setNavLink(0)}/>
                <SectionHeading border="white">Content Creation in Experience Manager</SectionHeading>
                <h3>Start with better collaboration between IT and marketing</h3>
                <p>Out-of-the-box components and styles free up IT and empower business users with easy authoring</p>
                <img className= "left-padding" src={Mobile_4_1} alt="" />

                <h3>Easily create experiences for any device</h3>
                <p>Visual editing, drag-and-drop templates, components, keyboard shortcuts, and visual search make building multi-channel experiences fast and easy.</p>
                <img className= "right-padding" src={Mobile_4_2} alt="" />
            </MobileSection>

            <MobileSection  sectionName="" textColor="black" bgColor="offwhite">
                <AnchorPoint sectionName="" onFire={() => setNavLink(1)}/>
                <SectionHeading border="red"> { region !== "en-us" ? 'Then Personalise them for every visitor' : 'Then Personalize them for every visitor'}</SectionHeading>
                
                <h3 className="red">{ region !== "en-us" ? 'With smart personalisation' : 'With smart personalization'}</h3>
                <p>Use artificial intelligence to automatically create and deliver one-to-one { region !== "en-us" ? 'personalised' :'personalized' } experiences.</p>
                <img src={Mobile_5_1} alt="" />

                <h3 className="red">{ region !== "en-us" ? 'And powerful optimisation' : 'And powerful optimization' }</h3>
                <p>{ region !== "en-us" ? 'Get each and' : 'Get' } every experience right with A/B and multi-variate testing and A.I. powered automation. </p>
                <img src={Mobile_5_2} alt="" />
            </MobileSection>

            <MobileSection sectionName="" textColor="white" bgColor="black">
                <AnchorPoint sectionName="" />
                <SectionHeading border="red">Work faster with built-in Creative Cloud integration</SectionHeading>

                <h3 className="white">Import media directly from Creative Cloud or Adobe Stock.</h3>
                <p>Edit images in Creative Cloud and see changes directly in Experience Manager.</p>
                <img className= "right-padding left-padding" src={Mobile_6_1} alt="" />

                <h3 className="white">Create consistency with a design system using UI kits that connect directly with Adobe XD.</h3>
                <p>Authors, publishers, and creative teams — even from external agencies — can collaborate in real-time.</p>
                <img className= "left-padding" src={Mobile_6_2} alt="" />
            </MobileSection>

            <MobileSection sectionName="" textColor="black" bgColor="offwhite">
                <AnchorPoint sectionName="" />

                <SectionHeading border="red">Let us do the heavy lifting with images</SectionHeading>

                <h3 className="red">Simplify boring stuff like metatagging.</h3>
                <p>Powerful AI automatically tags images, making it easy to find exactly what you need.</p>
                <img className= "right-padding left-padding" src={Mobile_7_1} alt="" />

                <h3 className="red">Use one image everywhere.</h3>
                <p>Create one master file and our AI auto generates unlimited versions for all screens and devices.</p>
                <img className= "right-padding left-padding" src={Mobile_7_2} alt="" />

                <h3 className="red">Increase quality, consistency, and performance. </h3>
                <p>Deliver consistent, quality assets through every channel while reducing page load times and increasing performance.</p>
                {/* <img className= "left-padding" src={Mobile_7_3} alt="" /> */}

            </MobileSection>

            <MobileSection setNavLink={() => setNavLink(1)} sectionName="scale" textColor="white" bgColor="red">
                <AnchorPoint sectionName="scale" onFire={() => setNavLink(1)}/>

                <SectionHeading border="white">Manage Your Content at Scale</SectionHeading>


                <p>Build, edit, and manage a single piece of content that is automatically tailored for use across all channels, including third-party touchpoints.</p>
                <img src={Mobile_8_1} alt="" />

                <p>Changes made in one version are published across all other versions and published directly or made available to headless applications.</p>
                <img src={Mobile_8_2} alt="" />


            </MobileSection>

            <MobileSection  sectionName="" textColor="white" bgColor="black">
                <AnchorPoint sectionName="" />

                <SectionHeading border="red">Accelerate time to market</SectionHeading>

                <p>Over 30 easy-to-style and production-ready components that are configurable in template. Create brand compliant visual variations of web components that adhere to company style guides with zero back-end development.</p>
                <img src={Mobile_9_1} alt="" className="left"/>
            </MobileSection>

            <MobileSection setNavLink={() => setNavLink(2)} sectionName="efficiency" textColor="black" bgColor="offwhite" bottomPadding={ false }>
                <AnchorPoint sectionName="efficiency" onFire={() => setNavLink(2)}/>


                <SectionHeading border="red">Get more from the first cloud-based enterprise CMS</SectionHeading>

                <p>Experience Manager intelligently detects capacity requirements and automatically scales to meet mission-critical service level availability.</p>
                <img src={Mobile_10_1} alt="" className="left" />

                <h3 className="red">Rely on built in security and resilience</h3>
                <p>Enterprise security with industry standards ensure all data and content is private and protected.</p>
                <img src={Mobile_10_2} alt="" className="small" />

                <h3 className="red">Simplify testing and maintenance</h3>
                <p>Reduce maintenance overheads with auto patching and service pack deployment.</p>
                <img src={Mobile_10_3} alt="" />

                <h3 className="red">A.I. Testing Capability</h3>
                <p>AI-driven, system-wide testing and pre-release scanning in staged environments ensures smooth publication to production.</p>
                <img src={Mobile_10_4} alt="" className="medium right" />

            </MobileSection>

            <MobileSection sectionName="" textColor="black" bgColor="offwhite" bottomPadding={ false }>
                <AnchorPoint sectionName=""/>

                <SectionHeading border="red">Go global with multiple sites and languages</SectionHeading>

                <h3 className="red">Quickly scale content for all regions and dialects with AI-powered translation.</h3>
                <p>Manage multiple sites and use artificial intelligence to create location specific variations of text while retaining overall meaning and key points.</p>
                <img src={Mobile_11_1} alt="" />

            </MobileSection>

            <MobileSection setNavLink={() => setNavLink(3)} sectionName="get-in-touch" textColor="" bgColor={ faas.completed ? "red" : "" } noAnimate={ true }>
                <AnchorPoint sectionName="get-in-touch" onFire={() => setNavLink(3)}/>
                {
                    faas.completed ?
                        <MobileComplete /> :
                        <Faas faas={ { ...faas, skippable: false, visible: true } } setFaas={ setFaas } hideFaas={ () => {} } tracking={ tracking }/>
                }
            </MobileSection>
            
            <Footer animationPauseFunc={ cookieClick } />
        </div>
    )
}

import React from 'react';
import '../styles/Toast.scss';

const Toast = props => {

    const { toastOpen, setToastOpen } = props;

    return (
        <div onAnimationEnd={ () => setToastOpen(false) } className={`toast-wrapper ${toastOpen ? 'open' : ''}`}>
            <div className="content-wrapper" variant="success" size="S" role="status">
                <i className="fas fa-check-circle"></i>
                <p className="content">Details successfully submitted!</p>
            </div>

            <div onClick={() => setToastOpen(false)} icon="close" size="XS" className="close-icon" coral-close="" role="img" aria-label="close"><i className="fas fa-times"></i></div>
        </div>
    )
}

export default Toast;
import EventEmitter from  './EventEmitter';

const scrollDecayMap = scrollAmt => {
    if (scrollAmt < 10) return 0.25;
    if (scrollAmt < 20) return 0.5;
    if (scrollAmt < 40) return 1;
    if (scrollAmt < 60) return 2;
    if (scrollAmt < 80) return 4;
    if (scrollAmt < 100) return 8;
    if (scrollAmt < 200) return 16;
    else return 32;
}

export default class InertiaHandler extends EventEmitter {
    constructor() {
        super();
        window.acc_debug.push('InertiaHandler created!')
        this.total = 0;
        this.maxScrollAmt = 1000;
        this.isTransitioning = false;
        this.scrollDirection = 'down';
        window.inertiaHandler = this;
    }

    increment(val) { this.total += val; }

    getIsTransitioning() {
        return this.isTransitioning;
    }

    setIsTransitioning(val) {
        this.isTransitioning = val;
    }

    applyInertia() {

        if (this.total <= 0) {
            this.total = 0;
            return this.setIsTransitioning(false);
        }
        
        if (this.total > this.maxScrollAmt) this.total = this.maxScrollAmt;

        const reduceBy = scrollDecayMap(this.total); 

        this.total -= reduceBy;
        
        this.emit('inertia_value', reduceBy);

        requestAnimationFrame(this.applyInertia.bind(this));

    }

    normalizeValue(value) {
        return value < 0 ? value * -1 : value;
    }

    beginInertia(event) {
        
        const scrollDeltaValue = navigator.userAgent.indexOf("Firefox") > -1 ? event.detail : event.deltaY;
        // window.acc_debug.push(`InertiaHandler.js: beginInertia: ${scrollDeltaValue}`);

        const newScrollDir = scrollDeltaValue < 0 ? 'up' : 'down';

        if (this.scrollDirection !== newScrollDir) {
            this.scrollDirection = newScrollDir;
            this.emit('new_scroll_direction', newScrollDir);
            this.total = 0;
        }

        const normalizedValue = this.normalizeValue(scrollDeltaValue);
        this.increment(normalizedValue);

        window.acc_debug.push(`InertiaHandler.js: isTransitioning before: ${this.getIsTransitioning()}`)

        if (this.getIsTransitioning()) return;
        window.acc_debug.push(`________________________________________________________________________________________________`);

        this.setIsTransitioning(true);
        this.applyInertia();
        window.acc_debug.push(`InertiaHandler.js: isTransitioning after: ${this.getIsTransitioning()}`)
    }


}



import EventEmitter from './EventEmitter';

export default class ActionPoint extends EventEmitter {
    constructor(timeline, props) {
        super();
        const { cb, once, point, enter, leave, name } = props;

        if (!point) throw new Error('point must be defined!');
        if (!name) throw new Error('name must be defined!');

        if (!cb && !enter && !leave) throw new Error(`If you're not defining any enter, leave or cb functions, what's the point of using this class?`);

        this.once = once ?? false;
        this.active = false;
        this.callbackFired = false;
        this.point = point;
        this.name = name;

        this.enter = enter;
        this.leave = leave;


        this.on('point_enter', () => {
            this.callbackFired = true;
            if (this.enter) this.enter(this);
            window.acc_debug.push(`ActionPoint: ${this.name}: enter function fired`);
        });

        this.on('point_leave', () => {
            this.callbackFired = false;
            if (this.enter) this.leave(this);
            window.acc_debug.push(`ActionPoint: ${this.name}: leave function fired`);
        });


        this.cb = () => {
            this.callbackFired = false;

            cb(this);
            window.acc_debug.push(`ActionPoint: ${this.name}: cb fired`);
            if (this.once) {
                const index = timeline.actionPoints.indexOf(this);

                timeline.actionPoints.splice(index, 1);
                window.acc_debug.push(`ActionPoint: ${this.name}: listener removed`);

            }
        }
    }

    toggleActive(value) {
        const previousValue = this.active;

        if (value === null) throw new Error('Needs a boolean value!');
        if (!previousValue && value) this.emit('point_enter');
        if (!!this.cb && this.callbackFired) this.cb();
        if (previousValue && !value) this.emit('point_leave');
        
        this.active = value;
    }
}
export default class EventEmitter {
    constructor() {
        this.events = {}
    };

    on(eventName, cb) {
        if (this.events[eventName]) return this.events[eventName].push(cb);
        else this.events[eventName] = [cb];
    };

    emit(eventName, data) {
        if (!this.events[eventName]) return;
        this.events[eventName].forEach(fn => fn(data))
    };
}


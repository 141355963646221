import React from 'react';
import '../styles/SectionHeading.scss';

const SectionHeading = props => {
    
    


    return (
        <h1 className={`border-${props.border} section-heading`}>{ props.children }</h1>
    )
}

export default SectionHeading;
import React, { useState, useEffect, useRef } from 'react';
import '../styles/ScrollAnimate.scss';

const ScrollAnimate = props => {
    
    const [isOnScreen, setOnScreen] = useState(false);

    const domRef = useRef();
    useEffect(() => {
      const copyRef = domRef;
        const options = { threshold: 0.7 };
        const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
            setOnScreen(true);

            observer.unobserve(copyRef.current);
          }
        }, options);
        
        observer.observe(copyRef.current);
        
        return () => observer.unobserve(copyRef.current);
      }, []);

    
    return (
        <div ref={ domRef } className={ `scroll-animate-container ${ isOnScreen ? 'visible' : '' }` }>
          { props.children }
        </div>
      )
}

export default ScrollAnimate;